import gql from 'graphql-tag';

export default gql`
query getPersonalizedVariableSetsItems($itemPath: String!) {
  getPersonalizedVariableSetsByPath(object: { item_path: $itemPath }) {
    personalized_variable_set_id
    name
    schema
  }
}
`;
